.equipCardCon {
  background-color: var(--w1);
  border-radius: 10px;
  //box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  padding: 30px 20px;
  .thirdCon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .inverterImage {
      max-width: 100%;
      max-height: 100%;
      margin-top: 50px;
    }
    .downText {
      font-size: 14px;
      margin-left: 10px;
    }

    .downloadCon:hover {
      cursor: pointer;
    }
  }

  .cardTitle {
    font-size: 14px;
  }
  .cardValue {
    font-size: 24px;
    margin-top: 10px;
  }
  .equipInfoUnit {
    margin-left: 5px;
  }
}
