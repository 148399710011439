.highPriorityCell {
  color: rgb(
    255,
    14,
    14
  ); /* Change the color to your desired high priority color */
  font-weight: 600;
}

.normalPriorityCell {
  color: black; /* Change the color to your desired normal priority color */
}
