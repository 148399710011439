.overviewInfoCon {
  text {
    font-family: "poppinsSemiBold";
    font-size: 14px;
  }

  h4 {
    font-family: "poppinsSemiBold";
    font-size: 26px;
    word-wrap: "break-word";
    margin-top: 7px;
  }

  .titleCon {
    margin-left: 7px !important;
  }
  .unitCon {
    margin-left: 7px !important;
  }

  .textCon {
    margin-left: 20px !important;
  }
}
