.topCon {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  .switchButtonInner {
    height: 44px;
  }
}

.preRepbottomCon {
  margin-top: 50px;
  .imgCon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .preReportImg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.Auth-form {
  padding-top: 40px;
  padding-bottom: 0px;
  border-radius: 8px;
  background-color: #ffffff;
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
    .Auth-form-title {
      text-align: center;
      margin-bottom: 1em;
      font-size: 24px;
      color: #252525;
      font-weight: 800;
    }
    .labelText {
      color: #252525;
      font-size: 14px;
    }
    .btnCon {
      display: flex;
      justify-content: center;
    }

    .button-style {
      position: relative;
      color: #ffffff;
      width: 80%;
      background: #020246;
      border-color: #ffffff;
      padding-top: 8px;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loader {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #252525;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .linkText {
      color: #252525;
      font-size: 14px;
      margin-top: 10px;
      text-decoration: underline;
      padding-left: 5px;
    }
  }
}
