.row {
  --bs-gutter-x: 0 !important;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
}
.usertitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}
.searchbar {
  align-items: center;
  justify-content: flex-end;
}
.searchbar .checkbox_input {
  background: var(--g9) !important;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
}
.addformbutton {
  margin-left: 20px;
}
.ml-1 {
  margin-left: 10px !important;
}
@media screen and (max-width: 420px) {
  .innerSearchbar {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .searchbar .checkbox_input {
    margin-left: 20px !important;
  }
  .addformbutton {
    margin-top: 10px;
  }
  .usertitle {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 800px) {
  .innerSearchbar {
    width: 100%;
  }
  .searchbar .checkbox_input {
    width: 95%;
  }
  .addformbutton {
    width: 95%;
    margin-top: 10px;
  }
}

.app-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.refresh-text {
  color: var(--primary_color);
  font-size: 14px;
  font-family: "poppinsMedium";
  text-align: center;
  margin-left: 20px;
}

.refresh-icon {
  color: #5072a7;
}
