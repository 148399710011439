@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"), url("../../fonts//TTF/Poppins-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"), url("../../fonts//TTF/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face{
  font-family: "Lato";
  src: local("Lato"), url("../../fonts/TTF/Lato-Regular.ttf");
  
}
