.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.error-page h1 {
  color: var(--r1);
  font-size: 10px;
  font-family: "poppinsMedium";
}

.error-page h2 {
  color: var(--g2);
  font-size: 30px;
  font-family: "poppinsSemiBold";
  margin-bottom: 1rem;
}

.error-page p {
  color: var(--black);
  font-size: 20px;
  font-family: "poppinsMedium";
}
.errorImage {
  margin: 10px 0px 50px 0px;
}
