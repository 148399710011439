/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600,700&display=swap"); */

:root {
  --radius: 1.5rem;
  --transition: all 400ms ease;
  --container-width-lg: 100%;
  --container-width-md: 100%;
  --container-width-sm: 100%;
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--color-black);
}
img {
  display: block;
}
a:hover {
  color: var(--color-black);
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins";
  background: var(--color-white);
  line-height: 1.25;
}
