.resource-det-title {
  font-size: 30px;
  font-family: "PoppinsSemiBold";
  color: var(--color-wateen);
}
.resource-det-desc {
  font-size: 14px;
  font-family: "PoppinsSemiBold";
  color: var(--color-wateen);
}
.resource-det-location {
  font-family: "poppinsSemiBold";
  color: var(--color-wateen);
  font-size: 30px;
}
.resource-det-day {
  font-family: "poppinsSemiBold";
  color: var(--color-wateen);
  font-size: 20px;
}
