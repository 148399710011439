.induction-step {
  .row {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    .col {
      flex: 1;
      padding: 10px;

      div {
        h1 {
          font-size: 16px;
          margin-bottom: 5px;
          color: var(--primary_color);
        }

        p {
          font-size: 15px;
          margin-bottom: 10px;
          color: var(--b2);
        }
      }
    }
  }
}
