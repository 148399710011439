.alarmDropCon {
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: row;
  //   align-items: center;
  //   margin-top: 20px;

  .select {
    width: 100%;
    height: 35px;
    padding: 0px 10px;
    border: none;
    border-radius: 20px;
    color: rgba(128, 129, 145, 1);
    appearance: none;
    font-size: 14px;
    font-family: "PoppinsMedium";
    line-height: 21px;
  }

  .select-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  .select option {
    background-color: var(--w1);
    color: var(--g6);
  }

  /* .select option:hover {
  background-color: #f6f6f6;
} */

  .icon-container {
    color: var(--g7);
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  /* .select option:checked {
  background-color: #007bff;
  color: #fff;
// } */
}
