.cardContainer {
  background-color: var(--b2);
  padding: 0px 0px 0px 0px;
  width: 100%;
  border-radius: 10px;
  padding: 20px;

  .rightCon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .leftCon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .solarImage {
    max-width: 100%;
    max-height: 100%;
  }
}

.chart-container {
  width: 100%;
  height: 400px;
  overflow: auto;
}

.ovCard1Main {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #454545;
  border-radius: 8px;
  padding: 16px 16px 16px 16px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  height: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary_color);
  }

  .ovCard1Cons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 14px;
    align-items: flex-end;
  }
  .ovCard1UpperCon {
    display: flex;
    justify-content: space-between;
  }

  .ovCard1LowerCon {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .ovCard1Title {
    font-family: "poppinsSemiBold";
    color: white;
    font-size: 20px;
  }

  .ovCard1Unit {
    font-size: 14px;
    color: white;
    margin: 0px 5px 0px 5px;
  }
  .ovCard1Value {
    font-size: 24px;
    margin-top: 5px;
    color: white;
  }
}

.ovCard2Main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #454545;
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  flex: 1;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary_color);
  }
  .ovCard2Title {
    font-size: 14px;
    color: white;
  }
  .ovCard2Value {
    font-size: 24px;
    margin-top: 5px;
    color: white;
  }
  .ovCard2Con {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .ovCard2Unit {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 14px;
      color: white;
    }
  }
}
.ovPeriodCon {
  margin-top: 10;
  margin-bottom: 10;
}
.ovProgressCon {
  display: flex;
  // background-color: yellow;
  height: 100%;
  // justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 8px 20px 0px 20px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  .ovProgressTitle {
    font-family: "poppinsSemiBold";
    color: var(--b1);
    font-size: 14px;
  }
  .ovProgressChartCon {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.ovCard3Main {
  display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  background-color: #454545;
  border-radius: 10px;
  padding: 16px 16px 16px 16px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  height: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary_color);
  }

  .ovCard1UpperCon {
    display: flex;
    justify-content: space-between;
  }

  .ovCard1LowerCon {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .ovCard1Title {
    font-family: "poppinsSemiBold";
    color: white;
    font-size: 20px;
  }

  .ovCard1Unit {
    font-size: 14px;
    color: white;
    margin: 0px 5px 0px 5px;
  }
  .ovCard1Value {
    font-size: 24px;
    margin-top: 5px;
    color: white;
  }
  .ptbtnCon {
    padding: 10px !important;
    display: flex;
    background-color: red !important;
  }
}
