.aiCenter {
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.weather-container {
  max-width: "300px";
  width: "100%";
  max-height: "400px";
  align-self: flex-end;
  align-items: end;
}
