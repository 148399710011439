.contactImgCon {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contactUsImg {
    height: 100%;
    width: 100%;
  }
}
.contactAiCenter {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.contactIconCon {
  padding: 10px;
  background-color: var(--primary_color);
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}
