:root {
  // Gray
  --g1: #a9a9a9;
  --g2: gray;
  --g3: #e8e8e8;
  --g4: #ccc;
  --g5: #888;
  --g6: #555;
  --g7: #808191;
  --g9: #e5e5e5;
  --g10: #f0f0f0;
  --g11: #454545;

  --w1: white;

  --b1: black;
  --b2: #252525;

  --r1: #ff0000;

  --y1: #ffff00;
  --y2: #e1ad01;
  --y3: #ff8000;
  --y4: #e6e600;

  --gr1: #224c8b;
  --gr2: #1976d2;
  --gr3: #638ac9;
  --gr4: #a1abc9;

  --o1: #ffa500;

  --color-green: #00b3ff; ///none
  --color-blue: #0000ff; /////none
  --color-wateen: #1976d2;
  --overlay: rgba(0, 0, 0, 0.5);

  // Gradient Color
  // Theme Color
  --theme_color: #4169e1;
  --theme_gr_color: linear-gradient(to right, #87ceeb, #4169e1);

  // card backGroubd Color
  --cardBg: #454545;

  //colors for new design
  --primary_color: #012d75;
  --neutral_grey: #454545;
  --secondary_color: #001a33;
  --neutral_light: #f0f0f0;
  --primary_blue_light_color: #012d7580;
  --neutral_dark: #2b2b2a;
  --gr10: #e6e6e6;
  --box_shadow: rgba(1, 45, 117, 0.25);
}
