.siteDetCon {
  .siteDetInfoCon {
    padding: 0px 20px 0px 20px;
  }
  .siteDetCardInnerCon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px 0px;
  }

  .weather-card-con {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    align-items: flex-end;

    .weather-overview-cols {
      cursor: pointer;

      .weather-overview-title {
        font-size: 24px;
        font-family: "PoppinsSemiBold";
        color: var(--color-wateen);
      }
      .weather-overview-desc {
        font-size: 12px;
        font-family: "PoppinsSemiBold";
        color: var(--color-wateen);
      }
    }
  }

  @media (max-width: 767px) {
    .weather-card-con {
      margin-top: 20px;
      align-self: center;
      align-items: center;
    }
  }
}
