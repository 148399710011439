.customRepCardTopCon {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.customRepCardBottomCon {
  margin-top: 100px;
  .imgCon {
    .preReportImg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
