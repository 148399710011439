@mixin applyPadding($padding) {
  padding: $padding;
}

.commonCardCon {
  background-color: var(--w1);
  border-radius: 8px;
  // box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  ;
  width: 100%;
  
  h4 {
    font-size: 20px;
  }

  .buttonCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  .slabs {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .slab {
    margin-right: 10px;
    padding: 2px 20px 2px 20px;
    border-width: 0px solid #ffffff;
    border-radius: 10px;
  }

  .active-map {
    background-color: #00ff00; /* Set your active slab color */
  }
  .inactive-map {
    background-color: #e74c3c; /* Set your inactive slab color */
  }

  .healthy-map {
    background-color: yellow; /* Set your healthy slab color */
  }
  .text {
    font-size: 14px;
    border-radius: 6px;
    margin-right: 6px;
    margin-top: 5px;
    font-weight: 500;
  }
}
