.appInputCon {
  padding: 0px 20px 10px 20px;
  height: 110px;
}

.appInputCon .ant-select-selector {
  max-height: 100px; /* Set a max height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}

.appInputCon .ant-select-selection-overflow {
  max-height: 80px; /* Ensure the tags container doesn't exceed the max height */
  overflow-y: auto; /* Enable scrolling within the tags container */
}

.form-group {
  margin-bottom: 20px; /* Add margin to ensure fields don't overlap */
}
